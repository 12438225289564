<template>
    <div class="fl-x">
        <div class="fl-x fs-lg-1" v-for="(item,i) in path" :key="i">
            <router-link to="/faq/" class="mr-lg-2 text-decoration-none font-lato-medium">{{ item }}</router-link>
            <div class="mr-lg-2 text">
                <span class="fa fa-chevron-right"/>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: 'BaseBreadcrumb',
    props: {
        path: {
            type: Array,
            default: null
        }
    }
};
</script>

<style scoped>

</style>
